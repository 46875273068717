import { Router } from '@reach/router'
import { graphql } from 'gatsby'
import React from 'react'

import ApologiesLanding from '../components/apologies/Landing'
import Layout from '../components/layout'

const Apologies = () => {
  return (
    <Layout>
      <Router>
        <ApologiesLanding path="/apologies" />
      </Router>
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default Apologies
