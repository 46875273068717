import React, { useEffect } from 'react'
import { RouteProps } from 'react-router-dom'

import ApologiesContainer from '../apologies/Container'
import Cookies from '../cookies/Container'
import FooterForm from '../footer-form/Container'
import SEO from '../seo'

const ApologiesLanding: React.FC<RouteProps<'/apologies'>> = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <SEO title="Apologies" />
      <ApologiesContainer />
      <FooterForm />
      <Cookies />
    </>
  )
}

export default ApologiesLanding
