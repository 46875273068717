import { useTranslation } from 'gatsby-plugin-react-i18next'
import React from 'react'

import './apologies.less'

const ApologiesContainer = () => {
  const { t } = useTranslation()
  const amazonUrl = 'mealpal.com/amazon'
  const amazon25Url = 'mealpal.com/amazon25'

  return (
    <>
      <div className="apologies">
        <h1 className="cms-container__title apologies__title">
          <i>Please Accept our Apologies</i>
        </h1>
        <h1 className="cms-container__title apologies__subtitle"> re: Lunch for $1</h1>
        <div className="apologies-text">
          <p className="apologies-text__text">
            If you’ve been around the Amazon buildings this week, you’ve hopefully seen the MealPal balloons and flyers
            offering{' '}
            <a className="apologies__link" href="http://mealpal.com/amazon">
              lunch for $1
            </a>
            . We have been so excited to welcome Amazon employees back to the office.{' '}
          </p>
          <p className="apologies-text__text__bold">
            {' '}
            Fun fact: before COVID, over 17,000 Amazonians used MealPal and saved over $3 million compared to the normal
            retail price of meals.
          </p>
          <p className="apologies-text__text">
            The goal of our{' '}
            <a className="apologies__link" href="http://mealpal.com/amazon">
              $1 promotion
            </a>{' '}
            was to make it as easy as possible for Amazonians to try MealPal. At the end of the promotion, accounts
            renew to normal MealPal plans unless the account is canceled.
          </p>
          <p className="apologies-text__text">
            We’ve received feedback from a few members of the Amazon community that it was not clear that accounts would
            renew after the $1 promotion. This was not our intention. Please accept our deepest apologies if this was
            unclear to you. (And, if your account renewed and you didn’t want it to, please email{' '}
            <a className="apologies__link apologies__link--black" href="mailto: hello@mealpal.com">
              hello@mealpal.com
            </a>
            .)
          </p>
          <p className="apologies-text__text">
            {' '}
            While this{' '}
            <a className="apologies__link" href="http://mealpal.com/amazon">
              $1 for Lunch
            </a>{' '}
            offer is available through the end of this week, we are also excited to introduce a new offer:{' '}
          </p>
          <div className="apologies-text__inline">
            <p className="apologies-text__text apologies-text__text--special">&#127880;&#127881;</p>
            <a className="apologies__link" href="http://mealpal.com/amazon25">
              Join MealPal and get a FREE $25 Amazon gift card
            </a>
            <p className="apologies-text__text apologies-text__text--special">&#127881;&#127880;</p>
          </div>
          <p className="apologies-text__text">
            LOL, right? I mean, you work at Amazon … and we are offering a{' '}
            <a className="apologies__link" href="http://mealpal.com/amazon25">
              $25 Amazon gift card
            </a>
            . But – who doesn’t need toilet paper, dog food, or any of the other thousands (millions?) of products
            readily available?
          </p>
          <p className="apologies-text__text-nobreak">Here’s how this promotion works:</p>
          <ul>
            <li className="apologies-text__text-nobreak">
              Go to{' '}
              <a className="apologies__link" href="http://mealpal.com/amazon">
                mealpal.com/amazon25
              </a>{' '}
              and sign up.
            </li>
            <li className="apologies-text__text-nobreak">
              You will be{' '}
              <a className="apologies__link" href="http://mealpal.com/amazon25">
                signing up
              </a>{' '}
              for either the 70 credit plan (10-12 lunches) for $85 or the 120 credit plan (10-16 lunches) for $139.{' '}
            </li>
            <li className="apologies-text__text-nobreak">
              Within 30 days of{' '}
              <a className="apologies__link" href="http://mealpal.com/amazon25">
                signing up
              </a>
              , we will email you the Amazon Gift Card*.
            </li>
          </ul>
          <p>
            At MealPal, it is our mission to help you save time and money on lunch, while also making it easy for you to
            discover great restaurants and easily coordinate lunch plans with co-workers.
          </p>
          <p className="apologies-text__text-nobreak">
            To sign up for the for either of these offers, please use the links below.
          </p>
          <a href={amazon25Url} className="apologies__button apologies__button--left red-cta">
            {t('FREE $25 Amazon Gift Card')}
          </a>
          <a href={amazonUrl} className="red-cta red-cta--reverse apologies__button apologies__button--right">
            {t('$1 Lunch Promo')}
          </a>
          <div className="apologies-text__disclosures">
            <p className="apologies-text__small apologies-text__small-nobreak">
              * We send gift cards via Tango Rewards, but would happily send them in bulk via Amazon if someone from the
              right team wants to help us get that set up! We have sent over $6,000,000 in Amazon gift cards to our
              members!
            </p>
            <p className="apologies-text__small apologies-text__small-nobreak">
              $25 Amazon Gift card promotion and $1 for lunch promotion are only available for members who do not have a
              current MealPal membership.{' '}
            </p>
            <p className="apologies-text__small">Promotions can’t be used together or with any other promotions. </p>
            <p className="apologies-text__small">Gift card is for Amazon®, a registered trademark of Amazon, Inc.</p>
            <p className="apologies-text__small">Expiration date: May 8, 2023</p>
          </div>
        </div>
      </div>
    </>
  )
}

export default ApologiesContainer
